<template>
    <div class="bkColor">
        <headTitle :title="title"></headTitle>
        <div class="title">
            <div style="display: flex;">
                <div>年份:</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="year" :options="yearOption" @change="getStatisticsPanel" />
                </van-dropdown-menu>
                <div style="background: #e6e6e6; border: 2px solid #e6e6e6;margin:0 10px"></div>
                <div>截止月份:</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="month" :options="monthOption" @change="getStatisticsPanel" />
                </van-dropdown-menu>
                <div style="background: #e6e6e6; border: 2px solid #e6e6e6;margin:0 10px"></div>
                <div>归属:</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="belong" :options="belongOption" @change="getStatisticsPanel" />
                </van-dropdown-menu>
            </div>
        </div>
        <div class="total">
            <div style="background: linear-gradient(113deg, #3495fc, #066ffd); box-shadow: 0px 8px 21px 0px rgba(25, 115, 253, 0.26);"
                class="totalBox">
                <div style="margin-bottom: 10px">目前已支出</div>
                <div>
                    <span>{{
                        getTenThousand(statisticsPanel.acExpend, true)
                    }}</span>
                    万元
                </div>
            </div>
            <div class="totalBox"
                style="background: linear-gradient(113deg, #fe6aac, #f42175);box-shadow: 0px 8px 21px 0px rgba(245, 37, 121, 0.26); ">
                <div style="margin-bottom: 10px">目前设定可支出</div>
                <div>
                    <span>{{
                        getTenThousand(statisticsPanel.planExpend, true)
                    }}</span>
                    万元
                </div>
            </div>
            <div class="totalBox"
                style="background: linear-gradient(113deg, rgb(251, 166, 47), rgb(251, 166, 47));box-shadow: 0px 8px 21px 0px rgba(245, 37, 121, 0.26); ">
                <div style="margin-bottom: 10px">项目总金额</div>
                <div>
                    <span>{{
                        getTenThousand(statisticsPanel.totalProjectAmount, true)
                    }}</span>
                    万元
                </div>
            </div>
        </div>
        <div class="detail">
            <div style="text-align: left; padding: 2vh 20px; font-weight: 600">
                支出明细
            </div>
            <div class="detailBox">
                <div class="detailChild" @click="goDataDetails(10)">
                    <span>项目</span>
                    <van-circle v-model="statisticsPanel.projectRate" :text="statisticsPanel.projectRate + '%'"
                        :stroke-width="80" size="60" layer-color="#F0F0F0" />
                    <div>
                        <span style="font-size: 18px">{{
                            getTenThousand(statisticsPanel.acProject, true)
                        }}</span>万元
                    </div>
                    <div>设定{{ getTenThousand(statisticsPanel.project) }}</div>
                </div>
                <div class="detailChild" @click="goDataDetails(20)">
                    <span>市场</span>
                    <van-circle v-model="statisticsPanel.marketRate" :text="statisticsPanel.marketRate + '%'"
                        :stroke-width="80" size="60" layer-color="#F0F0F0" color="#FBA62F" />
                    <div>
                        <span style="font-size: 18px">{{
                            getTenThousand(statisticsPanel.acMarket, true)
                        }}</span>万元
                    </div>
                    <div>设定{{ getTenThousand(statisticsPanel.market) }}</div>
                </div>
                <div class="detailChild" @click="goDataDetails(30)">
                    <span>行政</span>
                    <van-circle v-model="statisticsPanel.administrationRate"
                        :text="statisticsPanel.administrationRate + '%'" :stroke-width="80" size="60" layer-color="#F0F0F0"
                        color="#F64E92" />
                    <div>
                        <span style="font-size: 18px">
                            {{ getTenThousand(statisticsPanel.acAdministration, true) }}</span>万元
                    </div>
                    <div>设定{{ getTenThousand(statisticsPanel.administration) }}</div>
                </div>

                <div class="detailChild" @click="goDataDetails(50)">
                    <span>税费</span>
                    <van-circle v-model="statisticsPanel.taxRate" :text="statisticsPanel.taxRate + '%'" :stroke-width="80"
                        size="60" layer-color="#F0F0F0" color="#897AEC" />
                    <div>
                        <span style="font-size: 18px">
                            {{ getTenThousand(statisticsPanel.acTax, true) }}万元</span>
                    </div>
                    <div>设定{{ getTenThousand(statisticsPanel.tax) }}</div>
                </div>
                <div class="detailChild" @click="goDataDetails(60)">
                    <span>工资</span>
                    <van-circle v-model="statisticsPanel.salaryRate" :text="statisticsPanel.salaryRate + '%'"
                        :stroke-width="80" size="60" layer-color="#F0F0F0" color="#4BC6B5" />
                    <div>
                        <span style="font-size: 18px">{{
                            getTenThousand(statisticsPanel.acSalary, true)
                        }}</span>万元
                    </div>
                    <div>设定{{ getTenThousand(statisticsPanel.salary) }}</div>
                </div>
                <div class="detailChild" @click="goDataDetails(40)">
                    <span>交通</span>
                    <van-circle v-model="statisticsPanel.trafficRate" :text="statisticsPanel.trafficRate + '%'"
                        :stroke-width="80" size="60" layer-color="#F0F0F0" color="#E27D7C" />
                    <div>
                        <span style="font-size: 18px">{{
                            getTenThousand(statisticsPanel.acTraffic, true)
                        }}</span>万元
                    </div>
                    <div>设定{{ getTenThousand(statisticsPanel.traffic) }}</div>
                </div>
            </div>
        </div>

        <div class="detail">
            <div style="text-align: left; padding: 2vh 20px; font-weight: 600">
                其他明细
            </div>
            <div class="detailBox">
                <div class="detailChild" @click="goCollection">
                    <span>收款</span>
                    <van-circle v-model="collectionPercentage" :text="collectionPercentage + '%'" :stroke-width="80"
                        size="60" layer-color="#F0F0F0" />
                    <div>
                        <span style="font-size: 18px">{{
                            getTenThousand(statisticsPanel.gatherAmount, true)
                        }}</span>万元
                    </div>
                </div>
                <div class="detailChild" @click="goTransactionAmount(2)">
                    <span>往来支出</span>
                    <van-circle v-model="zero" text="--%" :stroke-width="80" size="60" layer-color="#F0F0F0"
                        color="#FBA62F" />
                    <div>
                        <div>
                            <span style="font-size: 18px">{{
                                getTenThousand(statisticsPanel.expendAmount, true)
                            }}</span>万元
                        </div>
                    </div>
                </div>
                <div class="detailChild" @click="goTransactionAmount(1)">
                    <span>往来收入</span>
                    <van-circle v-model="zero" text="--%" :stroke-width="80" size="60" layer-color="#F0F0F0"
                        color="#F64E92" />
                    <div>
                        <span style="font-size: 18px">
                            {{ getTenThousand(statisticsPanel.incomeAmount, true) }}</span>万元
                    </div>
                </div>
                <div class="detailChild" @click="goDepositExpenditure">
                    <span>保证金支出</span>
                    <van-circle v-model="zero" text="--%" :stroke-width="80" size="60" layer-color="#F0F0F0"
                        color="#897AEC" />
                    <div>
                        <span style="font-size: 18px">
                            {{ getTenThousand(statisticsPanel.guaranteeOut, true) }}</span>万元
                    </div>
                </div>
                <div class="detailChild" @click="goMarginIncome">
                    <span>保证金收入</span>
                    <van-circle v-model="zero" text="--%" :stroke-width="80" size="60" layer-color="#F0F0F0"
                        color="#4BC6B5" />
                    <div>
                        <span style="font-size: 18px">
                            {{ getTenThousand(statisticsPanel.guaranteeIn, true) }}</span>万元
                    </div>
                </div>
                <div class="detailChild" style="opacity: 0;">
                    <span>占位</span>
                    <van-circle v-model="zero" text="--%" :stroke-width="80" size="60" layer-color="#F0F0F0"
                        color="#4BC6B5" />
                    <div>
                        <span style="font-size: 18px">
                            {{ getTenThousand(statisticsPanel.guaranteeIn, true) }}</span>万元
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import { DropdownMenu, DropdownItem, Circle } from "vant";

Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Circle);
import { statisticsPanel } from "../api/api";
import { tenThousand, yearOption } from "../units/units";
export default {
    name: "dataAnalysis",
    components: {
        headTitle,
    },
    data() {
        return {
            title: "数据统计",
            month: 0,
            monthOption: [
                { text: "全部", value: 0 },
                { text: "1月", value: 1 },
                { text: "2月", value: 2 },
                { text: "3月", value: 3 },
                { text: "4月", value: 4 },
                { text: "5月", value: 5 },
                { text: "6月", value: 6 },
                { text: "7月", value: 7 },
                { text: "8月", value: 8 },
                { text: "9月", value: 9 },
                { text: "10月", value: 10 },
                { text: "11月", value: 11 },
                { text: "12月", value: 12 },

            ],
            year: 2024,
            yearOption: yearOption,
            belong: 0,
            belongOption: [
                { text: "全部", value: 0 },
                { text: "大美自然", value: 1 },
                { text: "中创科技", value: 2 },
                { text: "广云仓", value: 3 },
                { text: "大丰年", value: 4 },
            ],
            statisticsPanel: {
                acAdministration: 0,
                acExpend: 0,
                acMarket: 0,
                acProject: 0,
                acSalary: 0,
                acTax: 0,
                acTraffic: 0,
                administration: 0,
                administrationRate: 0,
                expendAmount: 0,
                gatherAmount: 0,
                incomeAmount: 0,
                market: 0,
                marketRate: 0,
                planExpend: 0,
                project: 0,
                projectRate: 0,
                salary: 0,
                salaryRate: 0,
                tax: 0,
                taxRate: 0,
                totalProjectAmount: 0,
                traffic: 0,
                trafficRate: 0,
                guaranteeIn: 0,
                guaranteeOut: 0,
            },
            zero: 0,
            queryForm: {

            }
        };
    },
    mounted() {
        this.getStatisticsPanel();

    },
    computed: {
        collectionPercentage: {
            get() {
                if (this.statisticsPanel.gatherAmount != 0 && this.statisticsPanel.totalProjectAmount != 0) {
                    return (this.statisticsPanel.gatherAmount / this.statisticsPanel.totalProjectAmount * 100).toFixed(2) * 1
                }
                return 0
            },
            set(newly) {
                return newly
            }
        }
    },
    methods: {
        getStatisticsPanel() {
            statisticsPanel(this.year, this.belong, this.month).then((res) => {
                if (res.data.code == 200) {
                    this.statisticsPanel = res.data.data;
                }
            });
        },
        //金额转万元单位
        getTenThousand(m, f = false) {
            return tenThousand(m, f);
        },
        // //计算百分比
        // collectionPercentage(numerator, denominator) {
        //     return (numerator / denominator * 100).toFixed(2)
        // },
        goDataDetails(type) {
            this.$router.push({
                path: "/dataDetailsList",
                query: { type, year: this.year, belong: this.belong }
            })
        },
        goCollection() {
            this.$router.push({
                path: '/pjCollection',
                query: {
                    routerPath: '/dataAnalysis',
                    year: this.year,
                    belong: this.belong
                }
            })
        },
        goMarginIncome() {
            //保证金收入页面跳转
            this.$router.push({
                path: '/marginIncome',
                query: {
                    routerPath: '/dataAnalysis',
                    year: this.year,
                    belong: this.belong
                }
            })
        },
        goDepositExpenditure() {
            this.$router.push({
                path: '/depositExpenditure',
                query: {
                    routerPath: '/dataAnalysis',
                    year: this.year,
                    belong: this.belong
                }
            })
        },
        goTransactionAmount(T) {
            this.$router.push({
                path: '/transactionAmount',
                query: {
                    routerPath: '/dataAnalysis',
                    type: T,
                    year: this.year,
                    belong: this.belong
                }
            })
        }

    },
};
</script>

<style lang="less" scoped>
/deep/ .van-circle__text {
    font-weight: 600;
    font-size: 12px;
}

.bkColor {
    width: 100vw;
    height: 100%;
    background: #f6f6f6;
}

.title {
    width: 100vw;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 0 20px;
    box-sizing: border-box;
}

.total {
    display: flex;
    justify-content: space-around;
    font-size: 12px;
    color: #fff;
    text-align: left;
    background: #fff;
}

.totalBox {
    width: 31.5%;
    max-width: 300px;
    min-height: 80px;
    border-radius: 10px;
    padding: 10px 10px;
    box-sizing: border-box;

    span {
        font-size: 18px;
    }
}

.detail {
    background: #fff;
}

.detailBox {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.detailChild {
    width: 30%;
    min-height: 145px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px 13px 57px 0px rgba(8, 73, 162, 0.11);
    border-radius: 24px;
    padding: 10px 0;
    margin-bottom: 2vh;
    color: #999999;
    font-size: 14px;
}

.detailChild span {
    font-weight: 600;
    color: #323233;
    font-size: 16px;
}
</style>